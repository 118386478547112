<template>
  <CCard>
    <CCardHeader>
      {{$t('Категории')}}
    </CCardHeader>
    <CCardBody>
      <CForm @submit.prevent="save(category)" v-for="(category, index) in this.categories" :key="index" class="cat_block">
        <CInput
            horizontal
            :value="category.title"
            :id="'input' + category.id"
        />
        <a to="" @click="save(category.id)" :title="$t('Обновить')"><CButton size="sm" color="success">{{$t('Обновить')}}</CButton></a>
        <a to="" @click="remove(category.id)" :title="$t('Удалить')"><CButton size="sm" color="danger">{{$t('Удалить')}}</CButton></a>
      </CForm>
      <CForm class="cat_block">
        <CInput
            horizontal
            id="input_new"
        />
        <a to="" @click="add()" :title="$t('Добавить')"><CButton size="sm" color="info">{{$t('Добавить')}}</CButton></a>
      </CForm>
      <router-link :to="'/calendar/' + this.$route.params.id"><CButton type="submit" size="sm" color="dark">{{ this.$t('Назад') }}</CButton></router-link>
      <notifications group="calendar" />
    </CCardBody>
  </CCard>
</template>

<script>
import axios from '@/modules/axios'

export default {
  name: 'CalendarAdd',
  components: {
  },
  data () {
    return {
      categories: []
    }
  },
  created() {
    this.getCategories()
  },
  methods: {
    save(categoryId) {
      const title = document.getElementById('input' + categoryId).value
      axios.post('/category/edit', {categoryId: categoryId, title: title}, {
        headers: {'content-type': 'application/json'}
      }).then(() => {
        this.$notify({ group: 'calendar', text: this.$t('Обновлено')})
      }).catch((error) => {
        this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
      })
    },
    add() {
      const title = document.getElementById('input_new').value
      axios.post('/category/add', {calendarId: this.$route.params.id, title: title}, {
        headers: {'content-type': 'application/json'}
      }).then(() => {
        this.getCategories()
        setTimeout(() => {
          document.getElementById('input_new').value = ''
        }, 100)
      }).catch((error) => {
        this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
      })
    },
    getCategories () {
      axios.get('/category/list', {
        params: {calendarId: this.$route.params.id}
      }).then((res) => {
        this.categories = res.data.data;
      }).catch((error) => {
        this.$notify({ group: 'calendar', text: error.response.data.message, type: 'error' })
      })
    },
    remove(id) {
      if(confirm(this.$t('Вы уверены, что хотите удалить категорию ?'))){
        axios.post('/category/remove', {
          id: id
        }, {
          headers: {'content-type': 'application/json'}
        }).then(() => {
          this.getCategories()
          setTimeout(() => {
            document.getElementById('input_new').value = ''
          }, 100)
        }).catch((error) => {
          this.$notify({group: 'calendar', text: error.response.data.message, type: 'error'})
        })
      }
    },
  }
}
</script>
<style lang="scss">
form button {
  margin-right: 2em;
}
.cat_block {
  .form-group {
    width: 50%;
    display: inline-block;
    margin-right: 2em;
    .col-sm-9 {
      max-width: 100%;
    }
  }
}
</style>
